import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import './bookinglist.css';

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const history = useHistory(); // Initialize useHistory

  useEffect(() => {
    const token = localStorage.getItem('dummy_token2');
    const headers = {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    };

    axios.get('https://fourband-app-2n2vh.ondigitalocean.app/v1/data/booking-list', { headers })
      .then(response => {
        setBookings(Array.isArray(response.data.data.results) ? response.data.data.results : []);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Function to handle click on booking with active status
  const handleBookingClick = (bookingId) => {
    history.push(`/booking/${bookingId}`);
  };

  return (
    <div className="booking-container">
      <h2 className="title">Your Bookings</h2>
      {bookings.length > 0 ? (
        bookings.map((booking, index) => (
          <div key={index} className="booking-wrapper" style={{ width: '100%' }}>
            <p className="order-date">Ordered on: <span>{new Date(booking.created_at).toLocaleDateString()}</span></p>
            <div
              className="booking-card"
              style={{ width: '100%', cursor: booking.is_active ? 'pointer' : 'default' }} // Change cursor if clickable
              onClick={() => booking.is_active && handleBookingClick(booking.booking_id)} // Make the whole card clickable if active
            >
              <div className="booking-details">
                <img src={booking.image} alt={booking.event_title} className="event-image" />
                <div className="event-info">
                  <h3 className="event-name">{booking.event_title}</h3>
                  <p className="event-datenew">{new Date(booking.event_date).toLocaleDateString()}</p>
                  <p className="ticket-count">{booking.quantity} Ticket</p>
                  <p className="amountnew">Amount : ₹{booking.amount}</p>
                </div>
              </div>
              <div className="separator"></div>
              <div className="status-container">
                <div className={`status-bar ${booking.is_active ? 'confirmed' : 'failed'}`}>
                  {booking.is_active ? 'Confirmed' : 'Failed'}
                </div>
                <p className="status-message">
                  {booking.is_active ? 'Hope you will enjoy the show!' : 'Oops! your booking failed'}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="no-bookings">No bookings available</p>
      )}
    </div>
  );
};

export default BookingList;
