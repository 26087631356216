import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ticket_booking.css';

const BookingTicket = () => {
  const [ticketData, setTicketData] = useState(null);
  const { booking_id } = useParams();

  useEffect(() => {
    if (booking_id) {
      const token = localStorage.getItem('dummy_token2');
      const headers = {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
      };

      const url = `https://fourband-app-2n2vh.ondigitalocean.app/v1/data/booking-details/${booking_id}`;
      
      axios.get(url, { headers })
        .then(response => {
          if (response.data.status === 200) {
            setTicketData(response.data.data);
          }
        })
        .catch(error => {
          console.error("Error fetching ticket data:", error);
        });
    }
  }, [booking_id]);

  const formatDate = (dateString, timeString) => {
    const date = new Date(dateString + 'T' + timeString);
    return date.toLocaleString('en-US', {
      weekday: 'short', // Wed
      day: '2-digit',   // 20
      month: 'short',   // Nov
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).replace(',', ' :'); // Replace comma with " :"
  };

  if (!ticketData) return <p>Loading...</p>;

  const { booking_id: bk_id, quantity, amount, ticket_type, created_at, event_id } = ticketData;
  const { title, place, date, time, image } = event_id;
  const formattedAmount = (amount / 1000000).toFixed(2);

  return (
    <div className="ticket-container">
      <div className="ticket">
        <div className="ticket-header">
          {/* Event Image on the left */}
          <img src={image} alt={title} className="event-image" />
          {/* Event details on the right */}
          <div className="event-details">
            <h2 className="event-title">{title}</h2>
            <p className="event-place">{place}</p>
            <p className="event-date">{formatDate(date, time)}</p>

          </div>
        </div>

        {/* Booking ID */}
        <div className="booking-id">
          BOOKING ID: {bk_id}
        </div>

        {/* QR Code */}
        <div className="qr-code">
          <QRCode value={bk_id} size={120} />
        </div>

        {/* Ticket Info */}
        <p className="ticket-type">{ticket_type}</p>
        <p className="ticket-quantity">{quantity} Ticket</p>
        <p className="booking-date">Booked on: {new Date(created_at).toDateString()}</p>

        {/* Amount */}
        <div className="amount">Total Amount: ₹{formattedAmount}</div>
      </div>
    </div>
  );
};

export default BookingTicket;
