import React from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";
import { commerce } from "../../lib/commerce";
import { useState, useEffect } from "react";
import "./style.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>;
import {
  AccessTimeRounded,
  FilterAltOffIcon,
  CalendarTodayRounded,
  CalendarTodayTwoTone,
  CategoryRounded,
  DuoRounded,
  HourglassEmptyTwoTone,
  HourglassFullRounded,
  LanguageRounded,
  PlaceSharp,
  BlockSharp,
  Filter1TwoTone,
} from "@material-ui/icons";

const createMarkup = (text) => {
  return { __html: text };
};

const ProductView = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [index, setIndex] = useState(0);

  const handleSwipe = (index) => {
    setCurrentImage(images[index]);
    setIndex(index);
  };
  const handleDotClick = (dotIndex) => {
    setIndex(dotIndex);
    setCurrentImage(images[dotIndex]);
  };

  const handleMultipleImages = (image, event) => {
    event.preventDefault();
    setCurrentImage(image);
  };

  const [product, setProduct] = useState({});
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const [moreFields, setMoreFields] = useState({});
  useEffect(() => {
    const id = window.location.pathname.split("/");

    // Fetch products when the component mounts
    axios
      .get("https://fourband-app-2n2vh.ondigitalocean.app/v1/data/events-list/")
      .then((response) => {
        console.log("dbhjsabdshbds...", response.data.data.results);
        const reslutArray = response.data.data.results;
        setMoreFields(response.data);
        const singleEvent = reslutArray.filter((event) => event.id == id[2]);
        setProduct(singleEvent[0]);
        setImages(singleEvent[0].images);
        setCurrentImage(singleEvent[0].images[0]);
      });
  }, []);

  const fetchProduct = async (id) => {
    const response = await commerce.products.retrieve(id);
    // console.log('single....',{ response });
    const { name, price, time, media, quantity, description } = response;
    setProduct({
      name,
      quantity,
      description,
      time,
      src: media.source,
      price: price.formatted_with_symbol,
    });
  };

  // useEffect(() => {
  //   const id = window.location.pathname.split("/");
  //   // fetchProduct(id[2]);
  // }, []);

  console.log("images..", images);

  return (
    <Container className="product-view custom-container">
      <Grid container className="custom-container">
        <Grid item xs={12} md={6} lg={6} className="image-wrapper">
        <SwipeableViews
  enableMouseEvents
  index={index}
  onChangeIndex={handleSwipe}
>
  {images.map((image, index) => (
    <img
      key={index}
      style={{aspectRatio: "1 / 1" }}
      src={image}
      alt={product.address}
    />
  ))}
</SwipeableViews>

          <div className="dots">
            {images.map((_, dotIndex) => (
              <div
                key={dotIndex}
                className={`dot ${index === dotIndex ? "active" : ""}`}
                onClick={() => setIndex(dotIndex)}
              />
            ))}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className="text"
          style={{ overflow: "hidden" }}
        >
          <br></br>
          <Typography
            variant="h2"
            style={{ fontFamily: "Verdana", fontSize: "26px", color: "red" }}
          >
            <b>{product.title}</b>
          </Typography>

          <Typography
            variant=""
            style={{ fontFamily: "Verdana", fontSize: "15px", color: "white" }}
          >
            <CalendarTodayRounded></CalendarTodayRounded>
            <b>
              {" "}
              {product.date} - {product.time}{" "}
            </b>
          </Typography>
          <br></br>

          <Typography
            style={{ fontFamily: "Verdana", fontSize: "18px", color: "white" }}
          >
            <PlaceSharp></PlaceSharp>
            <b> {product.place} </b>
          </Typography>

          <br></br>
          <Typography
            variant="p"
            style={{ fontFamily: "Verdana", fontSize: "", color: "white" }}
            dangerouslySetInnerHTML={createMarkup(product.description)}
          />
          <br></br>

          {/* Expansion Tile */}
          <ExpansionPanel
            className="expansion-panel"
            style={{
              border: "none",
              boxShadow: "none",
              backgroundColor: "black",
            }}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: isMobile ? "white" : "black",
                    transform: isMobile ? "translateY(-7px)" : "none",
                  }}
                />
              }
              aria-controls="panel1a-content"
            >
              <Typography
                variant="h3"
                style={{ fontFamily: "Verdana", fontSize: "" }}
              >
                More information
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="body1">
                {/* Additional information content goes here */}
                <Typography
                  variant="h3"
                  style={{ fontFamily: "Verdana", fontSize: "" }}
                >
                  <LanguageRounded></LanguageRounded>{" "}
                  {product?.more_fields?.language}
                </Typography>

                <Typography
                  variant="h3"
                  color="secondary"
                  style={{ fontFamily: "Verdana", fontSize: "" }}
                >
                  <BlockSharp> </BlockSharp> {product?.more_fields?.age} +
                </Typography>

                <Typography
                  variant="h3"
                  color="secondary"
                  style={{ fontFamily: "Verdana", fontSize: "" }}
                >
                  <HourglassFullRounded></HourglassFullRounded>{" "}
                  {product.duration} Hr
                </Typography>

                {/* <Typography variant="h3" color="secondary" style={{ fontFamily:"Verdana",fontSize:""}}>
           <CategoryRounded></CategoryRounded> {product.category}
          </Typography> */}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            className="expansion-panel"
            style={{
              border: "none",
              boxShadow: "none",
              backgroundColor: "black",
            }}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: isMobile ? "white" : "black",
                    transform: isMobile ? "translateY(-7px)" : "none",
                  }}
                />
              }
              aria-controls="panel1a-content"
            >
              <Typography
                variant="h3"
                style={{ fontFamily: "Verdana", fontSize: "" }}
              >
                Venue layout
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Typography variant="body1">
                {/* Additional information content goes here */}
                <img
                  src={product?.layout_image}
                  alt="Venue Layout"
                  style={{ width: "100%" }}
                />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            className="expansion-panel"
            style={{
              border: "none",
              boxShadow: "none",
              backgroundColor: "black",
            }}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: isMobile ? "white" : "black",
                    transform: isMobile ? "translateY(-7px)" : "none",
                  }}
                />
              }
              aria-controls="panel1a-content"
            >
              <Typography
                variant="h3"
                style={{ fontFamily: "Verdana", fontSize: "" }}
              >
                Artists
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Typography variant="body1">
                {/* Additional information content goes here */}
                <div className="artist-list">
                  <ul className="artist-list-items">
                    {product?.more_fields?.artists?.map((artist, index) => (
                      <li key={index} className="artist-item">
                        <img
                          src={artist.artist_url}
                          alt={artist.artist}
                          className="artist-image"
                        />
                        <br />
                        <b>{artist.artist}</b>
                      </li>
                    ))}
                  </ul>
                </div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            className="expansion-panel"
            style={{
              border: "none",
              boxShadow: "none",
              backgroundColor: "black",
            }}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: isMobile ? "white" : "black",
                    transform: isMobile ? "translateY(-8px)" : "none",
                  }}
                />
              }
              aria-controls="panel1a-content"
            >
              <Typography
                variant="h3"
                style={{ fontFamily: "Verdana", fontSize: "" }}
              >
                Terms and conditions
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Typography variant="h3">
                {/* Additional information content goes here */}
                <ul>
                  {product?.more_fields?.terms.map((term, index) => (
                    <li key={index}>{term}</li>
                  ))}
                </ul>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <br />
          <br></br>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* {product?.available_tickets > 1 ?
        (
          ):
      (
        <><p style={{textAlign:"center", color:"black", background:"yellow", padding:"2px 4px", borderRadius:"4px"}}>Tickets Sold Out!</p>  </>
      )
      } */}
              <Button
                size="large"
                className="custom-button"
                component={Link}
                to={`/select-ticet/${product.id}`}
                style={
                  isMobile
                    ? {
                        position: "fixed",
                        bottom: "0px",
                        right: "0px",
                        left: "0px",
                      }
                    : {}
                }
              >
                BOOK NOW
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductView;
