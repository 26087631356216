import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "./ticket.css"

const Ticket = () => {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState();
  const [error, setError] = useState(null);
  const { bookingID, id } = useParams();

  const isMobile = useMediaQuery('(max-width:600px)');

  const getAuthToken = () => {
    return localStorage.getItem('dummy_token2');
  };

  useEffect(() => {

  axios.get("https://fourband-app-2n2vh.ondigitalocean.app/v1/data/events-list/").then((response) => {
    const reslutArray = response.data.data.results;
    const singleEvent = reslutArray.filter((event)=>event.id == id)
    setEvent(singleEvent[0])
  });


    const fetchData = async () => {
      try {
        const data = {
          booking_id: bookingID,
          status: 'success',
          payment_method: 'UPI',
        };

        const headers = {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        };

        console.log({ data });

        await axios.post("https://fourband-app-2n2vh.ondigitalocean.app/v1/data/update-booking/", data, { 
          headers:headers
         });
        setLoading(false);  
      } catch (error) {
        console.error('Error updating booking:', error);
        setError(error.message || 'An error occurred');
        setLoading(false);
      }
    };

    fetchData();
  }, [bookingID]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='container'>
      <Row className="row">
        <Col className="col-12 make_center">
          <div
            className="qr_div"
          >
            <QRCode className='qr'  value={bookingID} renderAs="canvas" />
          </div>
          <Typography
            variant='h6'
            align='center'
            style={{color: isMobile ? "white" : "white"}}
          >
            Booking ID: {bookingID}
          </Typography>
          <Typography
            variant='h6'
            align='center'
            style={{color: isMobile ? "white" : "white"}}
          >
            Event: {event?.title}
          </Typography>
          <Typography
            variant='subtitle2'
            align='center'
            style={{color: isMobile ? "white" : "white"}}
          >
            Ticket Booking Successful <Link to="/">Go Back</Link>
          </Typography>
        </Col>
      </Row>
    </div>
  );
};

export default Ticket;






// curl -X 'GET' 'http://fourband-app-2n2vh.ondigitalocean.app/v1/data/booking-list/' -H 'accept: application/json' -H 'Authorization: 5f5ff175958cc2b3389ee0d53d5c40e3a9ff44af' -H 'X-CSRFToken: kpmn7Y26TvLiqkQ7tJuvwCKPdGfnk7I3z8tpYSAU27lDNsxYgRM05eZwK4UcAokk'
